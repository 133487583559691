<template>
  <app-form-input-text
    v-model="model"
    v-bind="binding"
    :class="{
      '[&_input]:text-2xl': type === 'password',
    }"
  >
    <template #append>
      <button type="button" @click="toggle()">
        <transition
          enter-active-class="transition"
          enter-from-class="scale-y-0 opacity-0"
          leave-active-class="transition"
          leave-to-class="scale-y-0 opacity-0"
          mode="out-in"
        >
          <app-icon
            :key="type"
            class="text-subtle"
            :icon="type === 'password' ? 'ph:eye' : 'ph:eye-closed'"
            :size="20"
          />
        </transition>
      </button>
    </template>
  </app-form-input-text>
</template>

<script lang="ts" setup>
import omit from "lodash/omit";

import { appFormInputProperties } from "../composables/form-input.hook";

const properties = defineProps({
  ...appFormInputProperties,
});

const [type, toggle] = useToggle("password", {
  falsyValue: "password",
  truthyValue: "text",
});

const binding = computed(() => {
  return {
    ...omit(properties, "modelValue"),
    type: type.value,
  };
});

const model = useVModel(properties, "modelValue");
</script>
